import React, {useState} from "react";
import { useEffect } from "react";
import {Modal} from 'react-bootstrap';
import Image from '../components/imagesComponents/image';
import popupStyle from "./customPopup.module.css"


const CustomPopup = (props) => {
   const [show, setShow] = useState(false);

   useEffect(()=>{
       setShow(props.popupProps)
   })

    return(
        <Modal show={show} onHide={()=>props.actionProps()}>
        {/* <Modal.Header  >Thank you for contact us</Modal.Header> */}
        <Modal.Body closeButton>
            <center><div className={popupStyle.logo}><Image/></div>
            <p className={popupStyle.text}>Thank you!<br/> We are going to reply you very soon.</p></center>
        </Modal.Body>
      </Modal>
    )
}
export default CustomPopup