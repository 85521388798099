import React,{useEffect,useState} from "react"
import {Container,Row,Col,Form,Button} from "react-bootstrap";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaPhoneSquareAlt } from "react-icons/fa";
import ProfileImage from "../components/imagesComponents/ProfileImage"
// import indexStyle from '../styles/index.module.css'
import contactStyle from "../styles/contact.module.css"
import CustomPopup from '../components/customPopup'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'50px'}

const defaultValues = {
  fullname: "",
  phone: "",
  email:"",
  message:""
}
const defaultErrors = {
  errorFullname: "",
  errorPhone: "",
  errorEmail:"",
  errorMessage:""
}

const ContactPage = () => {
  const [values, setValues] = useState({
    ...defaultValues
  })
  const [errors, setErrors] = useState({
    ...defaultErrors
  })

  const [successfullSubmit, setSuccessfullSubmit] = useState(false);

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    console.log(name, value)
    cleanErrors(name)
    setValues({...values,
      [name]: value
    })
  }

  const cleanErrors = (name) => {
    switch(name){
      case "fullname": setErrors({...errors, ["errorFullname"]:""});break;
      case "phone": setErrors({...errors, ["errorPhone"]:""});break;
      case "email": setErrors({...errors, ["errorEmail"]:""});break;
      case "message": setErrors({...errors, ["errorMessage"]:""});break;
      default:console.log("dont exist");
    }
  }
  const validateEmail = (mail) => {
    console.log('validate email:',/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail))
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)?true:false;
  }
  const validatePhoneNumber = (number) => {
    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(number)?true:false;
  }
  const closePopup = () => {
    setSuccessfullSubmit(false)
  }


  const handleSubmit = event => {
    event.preventDefault()
    console.log("submit:")
    console.log(values)
    let submitable = true
    let fieldOne=''
    let fieldTwo=''
    let fieldThree=''
    let fieldFour=''

    if(values.fullname===""){
      fieldOne = "You must insert your fullname"
      submitable = false
    }
    if(!validatePhoneNumber(values.phone)){
      fieldTwo ="You must insert your phone number"
      submitable = false
    }
    if(!validateEmail(values.email)){
      fieldThree="You must insert a valid email"
      submitable = false
    }
    if(values.message===""){
      fieldFour="You must insert a message"
      submitable = false
    }
    if(!submitable){
      setErrors({...errors,
        ["errorFullname"]:fieldOne,
        ["errorPhone"]:fieldTwo,
        ["errorEmail"]:fieldThree,
        ["errorMessage"]:fieldFour})
        return
    }
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_api_key:"xUpil9ij5Njs4lsha",
        fullname: values.fullname,
        phone_number: values.phone,
        email: values.email,
        message: values.message
      })
  };
  fetch('https://corbinbrooks.com/CMS/upstaging_contact', requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log("response: ", data)
        if(data.status===200){
          setValues(defaultValues)
          setSuccessfullSubmit(true)
        }
      });
  }

  return(
  <Layout>
    <SEO title="Contact" />
    <body className={contactStyle.font} >
    <Container style={background}>

      <Row>
        <Col sm={12} md={1}/>
        {/* <Col sm={12} md={4}>
          <div className={contactStyle.boxLeft}>
            <center >
            <div style={{minWidth:'150px',borderRadius:'10px'}}>
              <ProfileImage />
            </div>
            <center style={{marginTop:'20px'}}>
                <h4 className={contactStyle.title}>Susan Sparks</h4>
                <h6 className={contactStyle.subtitle}>Owner, Sres</h6>
                <div style={{height:'40px',lineHeight:'40px'}} >
                      <FaPhoneSquareAlt size="20px" style={{marginTop:'-8px'}}/>&nbsp;
                      <a href="tel:541-915-5310" className={contactStyle.phone}>541.915.5310</a>
                </div>
            </center>
            </center>
          </div>
  
        </Col> */}
        <Col sm={12} md={4} style={{padding:'4% 0'}}>
          {/* <div style={{padding:'5%',display:'flex'}}>
          <div style={{alignSelf:'center'}}>
          <p style={{fontWeight:'bolder'}} >Let our proven<br/>
          <label style={{fontSize:'20px',color:'#E9BF3C'}}>experience</label><br/>
          upstage the<br/>
          competition and<br/>
          get you even<br/>
          <label style={{fontSize:'20px',color:'#E9BF3C'}}>more</label> on the<br/>
          sale of your home!</p>
          </div>
          </div> */}
            <div style={{ 
                backgroundImage:`url("../../images/image-contact-form.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center top',
                backgroundSize:"contain",
                width:'100%',
                height:'400px'}}/>
            <center><div style={{height:'40px',lineHeight:'40px',marginTop:'20px'}} >
            <h5>Call us for a Consultation</h5>
                <FaPhoneSquareAlt size="30px" style={{marginTop:'-15px'}}/>&nbsp;
                <a href="tel:541-915-5310" className={contactStyle.phone}>541.915.5310</a>
            </div></center>

        </Col>
        <Col sm={12} md={6}>
          <div style={{padding:'5% 0 0 0'}}>
            <h4 className={contactStyle.titleForm}>Form for a Complimentary Consultation</h4>
            <p style={{textAlign:'justify'}}>If you would like more information, please use the form below to send us a message. 
              Or call Move-In Comfort directly with your questions and/ or to schedule a complimentary consultation.</p>

              <Form onSubmit={handleSubmit}>
              <Form.Group controlId="contactForm.ControlInput1">
                  <Form.Label>Fullname</Form.Label>
  
                  <Form.Control 
                    type="text" 
                    name="fullname"
                    placeholder="fullname"
                    value={values.fullname}
                    onChange={handleInputChange}
                    />
                  <Form.Text style={{color:'red'}} className={contactStyle.errors}>{errors.errorFullname}</Form.Text>
                </Form.Group> 
                <Form.Group  controlId="contactForm.ControlInput">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="phone" 
                    placeholder="Phone Number"
                    value={values.phone}
                    onChange={handleInputChange}
                    />
                    <Form.Text style={{color:'red'}} className={contactStyle.errors}>{errors.errorPhone}</Form.Text>
                </Form.Group> 
               <Form.Group  controlId="contactForm.ControlInput3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control 
                    type="text"
                    name="email" 
                    placeholder="Email"
                    value={values.email}
                    onChange={handleInputChange}
                    />
                    <Form.Text style={{color:'red'}} className={contactStyle.errors}>{errors.errorEmail}</Form.Text>
                </Form.Group> 
                <Form.Group  controlId="contactForm.ControlInput4">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                    name="message" 
                    value={values.message}
                    onChange={handleInputChange}
                    />
                    <Form.Text style={{color:'red'}} className={contactStyle.errors}>{errors.errorMessage}</Form.Text>
                </Form.Group>
                <Button variant="primary" color="#E9BF3C" type="submit">
                  Send Message
                </Button>
                <CustomPopup popupProps={successfullSubmit} actionProps={closePopup}/>
              </Form>
          </div>
        </Col>
        <Col sm={12} md={1}/>
      </Row>
    </Container>
    </body>
  </Layout>)
}

export default ContactPage